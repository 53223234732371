import React from 'react'

import { MEDIA_TYPES } from '@utils/constants'

import Error from '@components/Error'
import Photo from './Photo'
import Text from './Text'
import Video from './Video'
import VideoTranscription from './VideoTranscription'

const Media = ({ content }) => {
  switch (content.mediaType) {
    case MEDIA_TYPES.image:
      return <Photo content={content} />

    case MEDIA_TYPES.text:
      return <Text content={content} />

    case MEDIA_TYPES.video:
      return <Video content={content} />

    case MEDIA_TYPES.transcription:
      return <VideoTranscription content={content} />

    default:
      return <Error />
  }
}

export default Media
