import React from 'react'

import { METADATA_CSS_CLASSES } from '@utils/constants'

import Media from './Media'
import Metadata from '@components/Metadata'
import Screening from './Screening'

import styles from './styles.module.css'

const ContentCard = ({ content }) => {
  return (
    <div className={styles['content-card']}>
      {content.map((contentItem, idx) => (
        <div className={styles['content-item']} key={idx}>
          <Metadata className={METADATA_CSS_CLASSES.content} metadata={contentItem.metadata} />
          <Media content={contentItem} />
          <Screening results={contentItem.screening} />
        </div>
      ))}
    </div>
  )
}

export default ContentCard
