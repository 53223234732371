import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import Error from '@components/Error'
import Step from '@components/Step'

import { METADATA_CSS_CLASSES, STEP_ACTIONS } from '@utils/constants'

const Steps = () => {
  const { currentStep, data } = useCustomContext()

  switch (currentStep?.action) {
    case STEP_ACTIONS.review:
      return (
        <Step>
          <Step.Progress />
          <Step.Metadata className={METADATA_CSS_CLASSES.step} metadata={data.metadata} />
          <Step.Content />
          <Step.Nav />
        </Step>
      )

    case STEP_ACTIONS.submit:
      return (
        <Step>
          <Step.Progress />
          <Step.Summary />
          <Step.Nav showSubmit={true} />
        </Step>
      )

    default:
      return <Error />
  }
}

export default Steps
