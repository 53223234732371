import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

import styles from './styles.module.css'

const Filters = () => {
  const { getTranslation } = useLanguageContext()
  const { callbacks, currentUser, state } = useCustomContext()

  return (
    <div className={styles.filters}>
      {currentUser.permittedRoles.length > 1 && (
        <Input
          name='role'
          onChange={e => callbacks.setFilter({ role: e.target.value })}
          type='select'
          value={state.filters.role}
        >
          <option value=''>{getTranslation('All Roles')}</option>
          {Object.entries(currentUser.permittedRoles).map(([key, value]) => (
            <option key={key} value={value}>
              {getTranslation(value)}
            </option>
          ))}
        </Input>
      )}

      <Input
        name='email'
        onChange={e => callbacks.setFilter({ nameOrEmail: e.target.value })}
        placeholder={getTranslation('Search by name or email')}
        type='search'
        value={state.filters.nameOrEmail}
      />
    </div>
  )
}

export default Filters
