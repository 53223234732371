import React from 'react'

import ApiMetadata from './ApiMetadata'
import LinkMetadata from './LinkMetadata'
import PhotoMetadata from './PhotoMetadata'
import TextMetadata from './TextMetadata'

import { METADATA_TYPES } from '@utils/constants'

import styles from './styles.module.css'

const Metadata = ({ className, metadata }) => {
  if (!metadata.some(item => Object.values(METADATA_TYPES).includes(item.type))) return null

  return (
    <div className={styles[className]}>
      <TextMetadata metadata={metadata.filter(item => item.type === METADATA_TYPES.text)} />
      <PhotoMetadata metadata={metadata.filter(item => item.type === METADATA_TYPES.image)} />
      <LinkMetadata metadata={metadata.filter(item => item.type === METADATA_TYPES.link)} />
      <ApiMetadata metadata={metadata.filter(item => item.type === METADATA_TYPES.api)} />
    </div>
  )
}

export default Metadata
