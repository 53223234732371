import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext } from '@cvg/react-hooks'

import FlagContent from '@components/ContentCard/FlagContent'

import styles from '../styles.module.css'

const Select = ({ content, canFlagContent, name, value, ...rest }) => {
  const { currentStep, updateData } = useCustomContext()

  return (
    <div className={styles['text-input']}>
      <Input
        className={styles.select}
        error={currentStep.errors[name]}
        name={name}
        onChange={event => updateData(event, currentStep)}
        type='select'
        value={value}
        {...rest}
      >
        {content.allowedValues.map(item => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          )
        })}
      </Input>

      {canFlagContent && <FlagContent content={content} />}
    </div>
  )
}

export default Select
