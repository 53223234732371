import React from 'react'

import { Input } from '@cvg/react-components'
import { useCustomContext } from '@cvg/react-hooks'

import FlagContent from '@components/ContentCard/FlagContent'

import styles from '../styles.module.css'

const FlexTextarea = ({ content, canFlagContent, name, ...rest }) => {
  const { currentStep } = useCustomContext()
  const { readOnly = content.readOnly } = rest

  return (
    <div className={styles['text-input']} data-readonly={readOnly}>
      <Input
        aria-label={content.label}
        className={styles['flex-textarea']}
        error={currentStep.errors[name]}
        name={name}
        readOnly={readOnly}
        type='flexTextarea'
        {...rest}
      />

      {canFlagContent && <FlagContent content={content} />}
    </div>
  )
}

export default FlexTextarea
