import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'
import { useLanguageContext } from '@cvg/react-hooks'

import SubmitButton from './SubmitButton'

import styles from './styles.module.css'

const StepNav = ({ showSubmit = false }) => {
  const { currentStep, goToNextStep, goToPreviousStep, isSubmitting, submitContent, validateContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const next = () => {
    if (validateContent(currentStep)) {
      goToNextStep(currentStep)
    }
  }

  return (
    <div className={styles['step-nav']}>
      {currentStep.number > 1 && (
        <button name='back' onClick={() => goToPreviousStep(currentStep.number - 1)} type='button'>
          {getTranslation('Back')}
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' />
          </svg>
        </button>
      )}

      {!showSubmit && (
        <button name='next' onClick={next} type='button'>
          {getTranslation('Next')}
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
          </svg>
        </button>
      )}

      {showSubmit && <SubmitButton disabled={isSubmitting} onSubmit={submitContent} />}
    </div>
  )
}

export default StepNav
