import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import { TRANSLATION_TYPES } from '@utils/constants'

import InputType from '../../InputType'

import styles from './styles.module.css'

const TranslationSource = ({ contentItems }) => {
  const { currentStep, updateData } = useCustomContext()

  return (
    <div className={styles.source}>
      {contentItems.map(item => (
        <InputType
          aria-labelledby={`${TRANSLATION_TYPES.source}_language`}
          canFlagContent={false}
          content={item}
          key={item.id}
          name={`source_${item.id}`}
          onChange={event => updateData(event, currentStep, TRANSLATION_TYPES.source)}
          readOnly={item.translation.source.readOnly}
          value={item.translation.source.data}
        />
      ))}
    </div>
  )
}

export default TranslationSource
