import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

import { STEP_ACTIONS } from '@utils/constants'

import Flagged from './Flagged'

import styles from './styles.module.css'

const StepSummary = () => {
  const { comment, context, steps, updateComment } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const approved = steps.every(step => step.action === STEP_ACTIONS.submit || step.approved)

  return (
    <div className={styles['step-summary']}>
      <section>
        <h3>
          <strong>{getTranslation(approved ? 'Summary' : 'Flagged Content Summary')}</strong>
          {approved && <span>{getTranslation('All content was approved.')}</span>}
        </h3>

        {!approved && <Flagged />}

        <h3>
          <strong>{getTranslation("What's Next")}</strong>
          <span>{getTranslation(`NEXT_${context}_${approved ? 'APPROVED' : 'REJECTED'}`)}</span>
        </h3>
      </section>

      <section>
        <h3>{getTranslation('Additional Comments')}</h3>
        <Input
          name='comment'
          onChange={updateComment}
          placeholder={getTranslation('Enter any additional comments you may have.')}
          type='flexTextarea'
          value={comment}
        />
      </section>
    </div>
  )
}

export default StepSummary
