import React from 'react'

import { INPUT_TYPES } from '@utils/constants'

import FlexTextarea from './FlexTextarea'
import Select from './Select'

const InputType = ({ content, canFlagContent = true, ...rest }) => {
  const textInputType = content.allowedValues?.length ? INPUT_TYPES.select : INPUT_TYPES.flexTextarea

  switch (textInputType) {
    case INPUT_TYPES.flexTextarea:
      return <FlexTextarea content={content} canFlagContent={canFlagContent} {...rest} />
    case INPUT_TYPES.select:
      return <Select content={content} canFlagContent={canFlagContent} {...rest} />
  }
}

export default InputType
